import { bindGeTui } from "@/api/shop";
import router from "@/router";
import { Notification } from "element-ui";
// 是否打开调试模式
let debugMode = process.env.NODE_ENV !== "production";
/**
 * 推送管理器
 * @author huawei
 * 配置通知跳转路径
 */
const pushPathConfig = {
  11: {
    path: "/compliancecso/compliancecso-task-list", //CSO端 ｜ 医药公司给这个 CSO 公司分派了任务 ｜  CSO有“合规管理- 指标管理”权限的用户 ｜ 跳转 合规管理-指标管理-任务列表页面
    title: "收到新的任务，请您及时处理",
  },
  22: {
    path: "/compliance/compliance-childtask-list", // 医药公司端 ｜有新的待审核的任务（子任务全部审核通过，提醒对应的角色要审核任务） ｜ 医药公司端有“合规管理-指标管理”权限的用户 ｜ 跳转 医药公司端-合规管理-指标管理 对应任务的子任务列表页面
    title: "有新的待审核任务，请您及时处理",
  },
  23: {
    path: "/compliancecso/compliancecso-childtask-list", // CSO端 ｜有新的待审核的任务（子任务全部审核通过，提醒对应的角色要审核任务） ｜ CSO端有“合规管理-指标管理”权限的用户 ｜ 跳转 CSO端-合规管理-指标管理 对应任务的子任务列表页面
    title: "有新的待审核任务，请您及时处理", //compliancecso/compliancecso-childtask-list?targetId=1803253934673375266&taskId=1895409404506148866
    // /compliancecso/compliancecso-childtask-list?targetId=1803253934673375266&taskId=1895409404506148866
  },
  33: {
    path: "/compliancecso/compliancecso-salesman-certifyList", // CSO端 ｜人员资质平台审核驳回 ｜ CSO端有“合规管理-人员管理”权限的用户 ｜ 跳转 CSO端-合规管理-人员管理-人员资质管理
    title: "有人员资质被平台驳回，请您及时处理",
  },
  34: {
    path: "/compliancecso/compliancecso-salesman-list", // CSO端 ｜业务员平台审核驳回 ｜ CSO端有“合规管理-业务员管理”权限的用户 ｜ 跳转 CSO端-合规管理-人员管理-业务员管理
    title: "有业务员信息被平台驳回，请您及时处理",
  },
  41: {
    path: "/compliance/compliance-financialManager-list", // 医药公司端 ｜ 任务全部审核通过后，需医药公司上传打款凭证 ｜ CSO端有“合规管理-查看发票”权限的用户 ｜ 跳转 医药公司端-财务管理-上传凭证
    title: "任务已审核通过，请及时上传打款凭证",
  },
  42: {
    path: "/compliancecso/compliancecso-invoiceManager-list", // CSO端 ｜ 任务全部审核通过后，需CSO上传打款凭证 ｜ CSO端有“合规管理-查看发票”权限的用户 ｜ 跳转 CSO端-合规管理-待开发票
    title: "任务已审核通过，请及时上传发票",
  },
  51: {
    path: "/compliance/compliance-invoiceManager-list", // 医药公司端 ｜  有新的待审核发票 ｜ 医药公司端有“合规管理-查看发票”权限的用户 ｜ 跳转 医药公司端-合规管理-查看发票
    title: "有新的待审核发票，请您及时处理",
  },
  52: {
    path: "/compliancecso/compliancecso-invoiceManager-list", // CSO端 ｜  上传的发票凭证被驳回 ｜ CSO端有“合规管理-待开发票”权限的用户 ｜ 跳转 CSO端-合规管理-待开发票
    title: "有上传的发票被驳回，请您及时处理",
  },
  61: {
    path: "/compliance/compliance-financialManager-list", // 医药公司端 ｜  上传的财务凭证被驳回 ｜ 医药公司端有“合规管理-财务管理 ｜ 跳转 医药公司端-合规管理-财务管理
    title: "有上传的财务凭证被驳回，请您及时处理",
  },
  62: {
    path: "/compliancecso/compliancecso-financialManagerCso-list", // CSO端 ｜  有新的待审核财务凭证 ｜ CSO端有“合规管理-财务管理”权限的用户 ｜ 跳转 CSO端-合规管理-财务管理
    title: "有新的待审核财务凭证，请您及时处理",
  },
};
let GtPushManager = (function () {
  let instance = null;

  function GtPushManager() {
    if (instance) {
      return instance;
    }

    this.cid = null;
    instance = this;
  }

  GtPushManager.prototype.init = function (doctorId) {
    // if (!doctorId) {
    //   console.error("Doctor ID is required for initialization.");
    //   return;
    // }

    GtPush.setDebugMode(debugMode);
    GtPush.init({
      appid: "yJRPBAq9DV7nh5DkGgwR75",
      onClientId: (res) => {
        console.log(`gtpush onClientId: ${res.cid}`);
        this.cid = res.cid;
        GtPush.enableSocket(true); // 打开socket连接
        let oldCid = localStorage.getItem("gtpush_cid");
        let data = {
          oldCid: oldCid || "", // 上一次绑定的cid,让后端可以清理冗余数据
          cid: res.cid,
          source: "0", // 0: pc, 1: app
        };
        bindGeTui(data)
          .then((res) => {
            console.log(`gtpushBind: ${res}`);
            localStorage.setItem("gtpush_cid", this.cid);
          })
          .catch((err) => {
            console.error("Failed to bind client ID with doctor:", err);
          });
      },
      onlineState: (data) => {
        console.log(`gtpush onlineState: ${data.online}`);
      },
      onPushMsg: (res) => {
        if (!res.message) {
          console.error("No message received in onPushMsg.");
          return;
        }

        console.log(`onPushMsg gtpush onPushMsg`, res.message);
        let messageObj = {};

        try {
          messageObj = JSON.parse(res.message);
        } catch (ex) {
          console.error("Failed to parse message:", ex);
          return;
        }
        // 进一步处理 messageObj
        // pushPathConfig
        const { type } = messageObj;
        if (type) {
          const { path, title } = pushPathConfig[type];
          if (path) {
            const query = {};
            if (type == 11) {
              // 医药公司给这个 CSO 公司分派了任务
              query.targetId = messageObj.targetId;
            } else if (type == 22 || type == 23) {
              // 医药公司端 ｜有新的待审核的任务（子任务全部审核通过，提醒对应的角色要审核任务） ｜ CSO端 ｜有新的待审核的任务（子任务全部审核通过，提醒对应的角色要审核任务）
              query.targetId = messageObj.targetId;
              query.taskId = messageObj.taskId;
            }
            const noti = Notification({
              title: "提示",
              message: title,
              duration: 0,
              onClick: () => {
                router.push({ path, query });
                noti.close();
              },
            });
          }
        }
      },
      onError: (res) => {
        console.error(`gtpush onError`, res.error);
      },
    });
  };

  GtPushManager.prototype.offline = function () {
    console.log("gtpush offline");
    this.cid = null;
    instance = null;
    GtPush.enableSocket(false); // 关闭socket连接
  };

  return GtPushManager;
})();

export { GtPushManager };
