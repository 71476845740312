<template>
  <div class="classification">
    <div>
      <certifyAndSalesListTabs :activeName="'first'"></certifyAndSalesListTabs>
    </div>
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item prop="name" label="">
          <el-input
            style="width: 150px"
            v-model="formTool.name"
            placeholder="搜索业务员名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="userTel" label="">
          <el-input
            style="width: 150px"
            v-model="formTool.userTel"
            placeholder="搜索业务员账号"
            clearable
            size="small"
          />
        </el-form-item>
        <!-- 审核状态 待平台审核 平台审核不通过 平台审核通过 -->
        <el-form-item prop="auditStatus" label="">
          <el-select
            v-model="formTool.auditStatus"
            placeholder="筛选审核状态"
            size="small"
            style="width: 150px"
            clearable
          >
            <el-option label="待平台审核" value="0"></el-option>
            <el-option label="平台审核通过" value="1"></el-option>
            <el-option label="平台审核不通过" value="2 "></el-option>
          </el-select>
        </el-form-item>
        <!-- 提交时间 -->
        <el-form-item label="">
          <el-date-picker
            v-model="formTool.serachTimeRange"
            size="small"
            range-separator="至"
            start-placeholder="提交日期"
            end-placeholder="提交日期"
            clearable
            type="daterange"
            style="width: 280px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" plain @click="goExport"
          >导出</el-button
        >
        <el-button size="small" type="primary" @click="openOpDetail()"
          >新建</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
      >
        <template slot="type" slot-scope="scope">
          <!-- 类型 0 内部业务员 1 第三方业务员 -->
          {{
            scope.row.type === 0
              ? "内部业务员"
              : scope.row.type === 1
              ? "第三方业务员"
              : ""
          }}
        </template>
        <template slot="auditStatus" slot-scope="scope">
          <!-- 审核状态 0 待平台审核 1 平台审核通过 2 平台审核不通过 -->
          {{
            scope.row.auditStatus === 0
              ? "待平台审核"
              : scope.row.auditStatus === 1
              ? "平台审核通过"
              : scope.row.auditStatus === 2
              ? "平台审核不通过"
              : ""
          }}
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="openOpDetail(2, scope.row)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleEditArea(scope.row)"
              >业务员区域</el-link
            >
            <!-- 删除 -->
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleDelete(scope.row)"
              >删除</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="opDetailRef" @onRefresh="handleRefresh" />
    <!-- 业务员区域弹窗 -->
    <SalesAreaDialog ref="salesAreaDialogRef" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import MyTable from "@/components/myTable/index.vue";
import OpDetail from "./dialog/op-detail.vue";
import {
  csoMemberPage,
  csoMemberExport,
  csoMemberDelete,
} from "@/api/complianceCso/salesman";
import SalesAreaDialog from "./dialog/sales-area-dialog.vue";
import certifyAndSalesListTabs from "./components/certifyAndSalesListTabs.vue";
export default {
  components: {
    MyTable,
    OpDetail,
    SalesAreaDialog,
    certifyAndSalesListTabs,
  },
  data() {
    return {
      formTool: {
        name: "",
        userTel: "",
        auditStatus: "",
        serachTimeRange: [],
        subTimeStart: null,
        subTimeEnd: null,
      },
      tableData: [],
      tableOption: [
        { label: "业务员名称", prop: "name" },
        { label: "业务员账号", prop: "userTel" },
        { label: "类型", prop: "type", slot: true },
        { label: "审核状态", prop: "auditStatus", slot: true },
        { label: "更新时间", prop: "updateTime" },
        { label: "更新人", prop: "updateBy" },
        { label: "提交时间", prop: "subTime" },
        { label: "操作", prop: "tools", slot: true, width: "180px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  watch: {
    "formTool.serachTimeRange": {
      handler(val) {
        if (val.length) {
          this.formTool.subTimeStart = dayjs(val[0]).format("YYYY-MM-DD");
          this.formTool.subTimeEnd = dayjs(val[1]).format("YYYY-MM-DD");
        } else {
          this.formTool.subTimeStart = null;
          this.formTool.subTimeEnd = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 删除业务员
    handleDelete(row) {
      this.$confirm("是否确认删除此人，删除后不可恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          csoMemberDelete(row.id)
            .then(() => {
              this.$message.success("删除成功");
              this.getList();
            })
            .catch(() => {})
            .finally(() => {
              this.listLoading = false;
            });
        })
        .catch(() => {});
    },
    // 编辑业务员区域
    handleEditArea(row) {
      this.$refs.salesAreaDialogRef.publicOpen(row);
    },
    openOpDetail(action = 1, row = null) {
      this.$refs.opDetailRef.open({ action, row });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      delete params.serachTimeRange;
      if (!params.subTimeStart) {
        delete params.subTimeStart;
      }
      if (!params.subTimeEnd) {
        delete params.subTimeEnd;
      }
      this.listLoading = true;
      csoMemberPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    goExport() {
      csoMemberExport(this.formTool).then((res) => {
        console.log("导出信息成功", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 20px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 190px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
