<template>
  <div class="detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="518px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="content form-content">
        <div class="row big">
          <div class="tip">合同名称</div>
          <div class="value input fill">
            <el-select
              v-model="selectedContract"
              size="small"
              filterable
              placeholder="请选择合同名称"
              value-key="id"
            >
              <el-option
                v-for="item in contractList"
                :key="item.id"
                :label="item.contractName"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row big">
          <div class="tip">合作公司</div>
          <div class="value input fill">
            {{ selectedContract ? selectedContract.coopShopName : "" }}
          </div>
        </div>
        <div class="row big">
          <div class="tip">指标名称</div>
          <div class="value input fill">
            <el-input
              size="small"
              maxlength="50"
              v-model="name"
              :disabled="isLook"
              placeholder="一个指标可以有多个任务，请输入指标名称"
            ></el-input>
          </div>
        </div>
        <div class="row big">
          <div class="tip">指标金额</div>
          <div class="value input fill">
            <el-input
              size="small"
              type="number"
              maxlength="10"
              v-model.trim="price"
              :disabled="isLook || !canModifyPrice"
              placeholder="请输入这个指标下所有任务的总金额"
              @input="(e) => thresholdReg('price', e)"
            ></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">关闭</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { splitMoney } from "@/utils/validate";

import { contractPage, searchContract } from "@/api/compliance/contractManager";

import {
  contractTargetAdd,
  contractTargetEdit,
  contractTargetDetail,
} from "@/api/compliance/metricsManager";

export default {
  components: {},
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看
      dialogVisible: false,
      loading: false,

      id: null,
      contractList: [], //合同列表

      selectedContract: null, //选择的合同  {}
      name: "", //指标名称
      price: "", //指标金额
      targetTaskExist: false, //是否存在指标任务，存在：true，不存在：false，存在就不能修改金额
    };
  },
  computed: {
    title() {
      let txt = "指标";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else {
        txt = `新增${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
    showAuditOpinion() {
      return this.isLook && this.auditStatus === 2;
    },
    canModifyPrice() {
      let ret = true;
      if (this.id && this.targetTaskExist) {
        ret = false;
      }
      return ret;
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 1;
        this.id = options.id ? options.id : "";
        this.getDetail();
      }
      this.doLoadContractList();

      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.id = "";
      this.contractList = [];
      this.selectedContract = null;
      this.name = "";
      this.price = "";
      this.loading = false;
      this.targetTaskExist = false;
    },
    thresholdReg(key, value) {
      // 9999999.99
      if (value) {
        let reg = /^\d{1,7}(\.\d{0,2})?$/;
        if (!reg.test(value)) {
          this[key] = splitMoney(value, 7, 2);
        }
      }
    },
    getDetail() {
      if (this.id) {
        this.loading = true;
        contractTargetDetail(this.id)
          .then((data) => {
            this.loading = false;
            if (data) {
              this.name = data.name;
              this.price = data.price;
              this.targetTaskExist = data.targetTaskExist;

              this.selectedContract = {
                id: data.contractId,
                contractCode: data.contractCode,
                coopShopId: data.coopShopId,
                coopShopName: data.coopShopName,
              };
            }
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    handleSave() {
      if (!this.selectedContract) {
        this.$message.warning("请选择合同名称!");
        return;
      }
      if (!this.name) {
        this.$message.warning("请填写指标名称!");
        return;
      }
      if (!this.price && +this.price <= 0) {
        this.$message.warning("请填写指标金额!");
        return;
      }

      this.loading = true;

      const data = {
        id: this.id ? this.id : "",
        name: this.name,
        price: this.price,
        contractId: this.selectedContract.id,
        contractCode: this.selectedContract.contractCode,
        coopShopId: this.selectedContract.coopShopId,
        coopShopName: this.selectedContract.coopShopName,
      };

      if (this.id) {
        contractTargetEdit(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      } else {
        contractTargetAdd(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    doLoadContractList() {
      // let params = { current: 1, size: 100 };
      let params = { searchName: "" };
      searchContract(params).then((res) => {
        // this.contractList = res.records;
        this.contractList = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: auto;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }

          ::v-deep .el-select {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  ::v-deep .el-upload {
    width: auto;
  }
  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 240px;
  }
}
</style>
