<template>
  <div class="payMain">
    <div class="on-line">
      <div class="normalText">
        <el-checkbox v-model="onLine" @change="handleChangeOnlineStatus"
          >线上支付是否开通</el-checkbox
        >
      </div>
      <el-divider></el-divider>
      <div v-show="onLine">
        <div class="kb-line">线上订单会收到下述康贝账号中</div>
        <div class="kb-line">
          康贝账号：{{ onLineData.kangBeiAccount || "" }}
        </div>
      </div>
    </div>
    <div>
      <div class="normalText">
        <el-checkbox v-model="payType" @change="changeCheckBox"
          >是否使用线下支付</el-checkbox
        >
      </div>
      <el-divider></el-divider>
      <el-form
        label-position="top"
        :model="userForm"
        ref="userForm"
        :disabled="true"
        v-if="payType"
      >
        <el-form-item label="收款户名" prop="name">
          <el-input
            placeholder="请输入"
            v-model="userForm.name"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input
            placeholder="请输入"
            v-model="userForm.bank"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="account">
          <el-input
            placeholder="请输入"
            v-model="userForm.account"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="开通线下支付"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span
        >缴纳保证金后才能开启。缴纳保证金会使店铺排序更靠前，获得更多商品曝光。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            payType = false;
          "
          size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="jiaona" size="small"
          >去缴纳</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="开通线下支付"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleForm"
      width="30%"
      :before-close="handleCloseForm"
    >
      <el-form
        label-position="right"
        label-width="80px"
        :rules="userRules"
        :model="user"
        ref="form"
      >
        <el-form-item label="收款户名" prop="name">
          <el-input placeholder="请输入" v-model="user.name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input placeholder="请输入" v-model="user.bank"></el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="account">
          <el-input placeholder="请输入" v-model="user.account"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisibleForm = false;
            payType = false;
          "
          size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="submit" size="small">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  updateOfflinePayment,
  queryOfflinePayment,
  queryOnlinePayment,
  updateOnlinePayment,
} from "@/api/shop";
export default {
  props: {
    boundMoney: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      onLine: true,
      dialogVisible: false,
      dialogVisibleForm: false,
      payType: false,
      userForm: {
        name: "",
        bank: "",
        account: "",
      },
      user: {
        name: "",
        bank: "",
        account: "",
      },
      userRules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        bank: [{ required: true, message: "请输入", trigger: "blur" }],
        account: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      saveEnable: false,
      onLineData: {}, // 线上支付
    };
  },
  async mounted() {
    this.query();
  },
  methods: {
    handleChangeOnlineStatus() {
      if (!this.onLine) {
        // 二次确认
        this.$confirm("关闭后将无法使用线上支付？确定关闭", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.updateOnlinePaymentRequest(false);
          })
          .catch(() => {
            this.onLine = true;
          });
      } else {
        this.updateOnlinePaymentRequest(true);
      }
    },
    updateOnlinePaymentRequest(status) {
      let obj = {
        onlinePaymentFlag: status ? "YES" : "NO", //offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
      };
      updateOnlinePayment(obj).then((res) => {
        if (res) {
          this.$message.success("更新成功");
          this.query();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.payType = false;
    },
    handleCloseForm() {
      this.dialogVisibleForm = false;
      this.payType = false;
    },
    // 查询是否开启
    async query() {
      const resp = await queryOfflinePayment({ shopId: null }); //accountName: "1"depositBank: "2"id: "1721728830094307330"offlinePaymentFlag: "YES"receivablesAccount: "3"
      if (resp) {
        this.userForm.name = resp.accountName;
        this.userForm.bank = resp.depositBank;
        this.userForm.account = resp.receivablesAccount;
        this.payType = resp.offlinePaymentFlag == "YES";
      }
      const resp2 = await queryOnlinePayment(); //onlinePaymentFlag	是否开启线上支付（YES：开启，NO：未开启）
      if (resp2) {
        this.onLine = resp2.onlinePaymentFlag == "YES";
        this.onLineData = resp2;
      }
    },
    // 去缴纳保证金
    jiaona() {
      this.$router.push("/financialcenter/surety-index");
    },
    // 切换是否开通
    changeCheckBox(val) {
      if (val) {
        if (this.boundMoney != 0) {
          this.user = { ...this.userForm };
          this.dialogVisibleForm = true;
        } else {
          this.dialogVisible = true;
        }
      } else {
        if (this.boundMoney != 0) {
          this.submitOff();
        }
      }
    },
    async submit() {
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          let obj = {
            offlinePaymentFlag: this.payType ? "YES" : "NO", //offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
            accountName: this.user.name, //收款户名
            depositBank: this.user.bank, //开户银行
            receivablesAccount: this.user.account, //收款账号
          };
          const resp = await updateOfflinePayment(obj);
          if (resp) {
            this.$message.success("更新成功");
            this.dialogVisibleForm = false;
            this.query();
          }
        }
      } catch (error) {}
    },
    async submitOff() {
      try {
        const valid = await this.$refs.userForm.validate();
        if (valid) {
          let obj = {
            offlinePaymentFlag: this.payType ? "YES" : "NO", //offlinePaymentFlag	是否开启线下支付（YES：开启，NO：未开启）
            accountName: this.userForm.name, //收款户名
            depositBank: this.userForm.bank, //开户银行
            receivablesAccount: this.userForm.account, //收款账号
          };
          const resp = await updateOfflinePayment(obj);
          if (resp) {
            this.$message.success("更新成功");
            this.query();
          }
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss" scoped>
.payMain {
  padding: 24px;
  display: flex;
  .on-line {
    width: 30%;
  }
  .normalText {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    line-height: 1.5;
  }
  .kb-line {
    font-size: 14px;
    color: #262626;
    margin-bottom: 12px;
  }

  .inLine {
    display: flex;
    align-items: center;

    .infoText {
      font-size: 14px;
      font-weight: 500;
      color: orange;
      margin-left: 12px;
    }
  }
}
</style>
