<template>
  <div class="classification">
    <div>
      <certifyAndSalesListTabs :activeName="'second'"></certifyAndSalesListTabs>
    </div>
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item prop="name" label="">
          <el-input
            style="width: 240px"
            v-model="formTool.name"
            placeholder="搜索人员姓名"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="tel" label="">
          <el-input
            style="width: 240px"
            v-model="formTool.tel"
            placeholder="搜索联系方式"
            clearable
            size="small"
          />
        </el-form-item>
        <!-- 审核状态 待平台审核 平台审核不通过 平台审核通过 -->
        <el-form-item prop="auditStatus" label="">
          <el-select
            v-model="formTool.auditStatus"
            placeholder="筛选审核状态"
            size="small"
            style="width: 150px"
            clearable
          >
            <el-option label="待平台审核" value="1"></el-option>
            <el-option label="平台审核通过" value="2"></el-option>
            <el-option label="平台审核不通过" value="3 "></el-option>
          </el-select>
        </el-form-item>
        <!-- 人员状态 启用 停用 -->
        <el-form-item prop="enableStatus" label="">
          <el-select
            v-model="formTool.enableStatus"
            placeholder="筛选人员状态"
            size="small"
            style="width: 150px"
            clearable
          >
            <el-option label="启用" value="2"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" plain @click="goExport"
          >导出</el-button
        >
        <el-button size="small" type="primary" @click="openOpDetail()"
          >新建</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
      >
        <template slot="resourceType" slot-scope="scope">
          {{ resourceTypeMap[scope.row.resourceType] }}
        </template>
        <template slot="auditStatus" slot-scope="scope">
          {{ auditStatusMap[scope.row.auditStatus] }}
        </template>
        <!-- enableStatus	人员启用状态: 1-未启用,2-已启用	 -->
        <template slot="enableStatus" slot-scope="scope">
          <el-switch
            v-model="scope.row.enableStatus"
            active-color="#f5222d"
            inactive-color="#8c8c8c"
            :active-value="2"
            :inactive-value="1"
            @change="handleEnableStatusChange(scope.row)"
            :disabled="scope.row.auditStatus != '2'"
          ></el-switch>
        </template>
        <template slot="uploadDiploma" slot-scope="scope">
          {{ scope.row.uploadDiploma ? "✅" : "❌" }}
        </template>
        <template slot="uploadPhCert" slot-scope="scope">
          {{ scope.row.uploadPhCert ? "✅" : "❌" }}
        </template>
        <template slot="uploadOther" slot-scope="scope">
          {{ scope.row.uploadOther ? "✅" : "❌" }}
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="openOpDetail(3, scope.row)"
              >查看</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="openOpDetail(2, scope.row)"
              >编辑</el-link
            >
            <!-- 删除 -->
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleDelete(scope.row)"
              >删除</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <CertifyDetail ref="certifyDetailRef" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import MyTable from "@/components/myTable/index.vue";
import CertifyDetail from "./dialog/certify-detail.vue";
import certifyAndSalesListTabs from "./components/certifyAndSalesListTabs.vue";
import {
  csoResourceUserPage,
  csoResourceUserExport,
  csoResourceUserSwitchEnable,
  csoResourceUserDelete,
} from "@/api/complianceCso/salesman";
import { debounce } from "lodash";
export default {
  components: {
    MyTable,
    CertifyDetail,
    certifyAndSalesListTabs,
  },
  data() {
    return {
      formTool: {
        name: "",
        tel: "",
        auditStatus: "",
        enableStatus: "",
      },
      resourceTypeMap: {
        1: "学术老师",
      },
      //   	审核状态: 1-待审核,2-审核通过,3审核驳回
      auditStatusMap: {
        1: "待平台审核",
        2: "平台审核通过",
        3: "平台审核不通过",
      },
      enableStatusMap: {
        1: "停用",
        2: "启用",
      },
      tableData: [],
      tableOption: [
        { label: "人员姓名", prop: "name" },
        { label: "联系方式", prop: "tel" },
        { label: "人员类型", prop: "resourceType", slot: true },
        { label: "毕业证", prop: "uploadDiploma", slot: true },
        { label: "医生/药师资格证", prop: "uploadPhCert", slot: true },
        { label: "其他", prop: "uploadOther", slot: true },
        { label: "审核状态", prop: "auditStatus", slot: true },
        { label: "人员状态", prop: "enableStatus", slot: true },
        { label: "更新时间", prop: "updateTime" },
        { label: "更新人", prop: "updateBy" },
        { label: "操作", prop: "tools", slot: true, width: "180px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    // 删除人员资质
    handleDelete(row) {
      this.$confirm("是否确认删除此人，删除后不可恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          csoResourceUserDelete(row.id)
            .then(() => {
              this.$message.success("删除成功");
              this.getList();
            })
            .catch(() => {})
            .finally(() => {
              this.listLoading = false;
            });
        })
        .catch(() => {});
    },
    // 打开弹窗
    openOpDetail(action = 1, row = null) {
      this.$refs.certifyDetailRef.open({ action, row });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      csoResourceUserPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
        })
        .catch(() => {})
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleEnableStatusChange: debounce(function (row) {
      csoResourceUserSwitchEnable(row.id).then((res) => {
        if (res) {
          this.$message.success("操作成功");
          this.handleRefresh();
        }
      });
    }, 1000),
    handleRefresh() {
      this.getList();
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    goExport() {
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      csoResourceUserExport(params).then((res) => {
        console.log("导出信息成功", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 20px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 190px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
