var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "harf-block" }, [
    _vm.showFormLeft
      ? _c("div", { staticClass: "big-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "form-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("ERP编码")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    on: { input: _vm.medicineCodeInput },
                    model: {
                      value: _vm.formIn.medicineCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "medicineCode", $$v)
                      },
                      expression: "formIn.medicineCode",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "positive-integer",
                        rawName: "v-positive-integer",
                      },
                    ],
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled: _vm.formIn.beSynStock,
                      maxlength: "8",
                    },
                    model: {
                      value: _vm.formIn.goodsSkuInventory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "goodsSkuInventory", $$v)
                      },
                      expression: "formIn.goodsSkuInventory",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "value fill" },
                [
                  _c(
                    "el-checkbox",
                    {
                      ref: "checkboxSynStock",
                      on: { change: _vm.stockChange },
                      model: {
                        value: _vm.formIn.beSynStock,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "beSynStock", $$v)
                        },
                        expression: "formIn.beSynStock",
                      },
                    },
                    [_vm._v("自动同步库存")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("价格")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                      disabled: "",
                    },
                    model: {
                      value: _vm.minCooperativeIncome,
                      callback: function ($$v) {
                        _vm.minCooperativeIncome = $$v
                      },
                      expression: "minCooperativeIncome",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("市场价")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.formIn.marketPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "marketPrice", $$v)
                      },
                      expression: "formIn.marketPrice",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("建议零售价")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.formIn.purchasePrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "purchasePrice", $$v)
                      },
                      expression: "formIn.purchasePrice",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", size: "small" },
                      model: {
                        value: _vm.formIn.goodsSkuUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "goodsSkuUnit", $$v)
                        },
                        expression: "formIn.goodsSkuUnit",
                      },
                    },
                    _vm._l(_vm.unitList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "positive-integer",
                        rawName: "v-positive-integer",
                      },
                    ],
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      maxlength: "4",
                    },
                    model: {
                      value: _vm.formIn.startWholesale,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "startWholesale", $$v)
                      },
                      expression: "formIn.startWholesale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "value fill" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formIn.useTimes,
                        callback: function ($$v) {
                          _vm.$set(_vm.formIn, "useTimes", $$v)
                        },
                        expression: "formIn.useTimes",
                      },
                    },
                    [_vm._v("倍数购买")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.expireTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "expireTime", $$v)
                      },
                      expression: "formIn.expireTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formIn.producedTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formIn, "producedTime", $$v)
                      },
                      expression: "formIn.producedTime",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "big-block" }, [
      _vm._m(6),
      _c("div", { staticClass: "form-content" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(7),
          _c("div", { staticClass: "value input goods-title" }, [
            _vm._v(" " + _vm._s(_vm.formIn.goodsSkuTitle) + " "),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("产品批号")]),
          _c(
            "div",
            { staticClass: "value input" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formIn.batchNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.formIn, "batchNumber", $$v)
                  },
                  expression: "formIn.batchNumber",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("价格/库存")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v(" 总库存"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("单位"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("起批量"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("有效期至"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("生产日期"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-block" }, [
      _c("span", { staticClass: "text" }, [_vm._v("图片/其他")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip" }, [
      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
      _vm._v("商品标题"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }